import { Heading, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'

const SocialCardContent: React.FC<
  React.PropsWithChildren<{ headingText: string; bodyText: string; bodyName: string; highlightColor: string }>
> = ({  bodyText, highlightColor, bodyName }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const isSmallerScreen = isMobile || isTablet
//   const split = headingText.split(' ')
//   const lastWord = split.pop()
//   const remainingWords = split.slice(0, split.length).join(' ')

  return (
    <Flex
      minHeight={[null, null, null, null]}
      height="250px"
      maxWidth="200px"
      flexDirection="column"
      // justifyContent="flex-end"
      mt={[null, null, null, null]}
    >
      {/* {isSmallerScreen && remainingWords.length > 13 ? (
        // <Heading scale="lg">{remainingWords}</Heading>
      ) : (
        // <Heading scale="xl">{remainingWords}</Heading>
      )} */}
      <Heading color={highlightColor} scale="xl" mb="24px">
        {/* {lastWord} */}
      </Heading>
      <Text color="textSubtle" mt={["36px", null, null, '36px']}>{bodyText}</Text>
      <Text color="textSubtle" style={{color: "#ADDB44"}} mt={["16px", null, null, '16px']}>{bodyName}</Text>

    </Flex>
  )
}

export default SocialCardContent