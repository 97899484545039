import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import Image from 'next/image'
import useSWRImmutable from 'swr/immutable'
import IconCard, { IconCardData } from '../IconCard'
import CryptoCardContent from './CryptoCardContent'
import Liqui from '../../../../../public/images/home/b.svg'
import Farms from '../../../../../public/images/home/Group.svg'
import Stake from '../../../../../public/images/home/stake.svg'
import Swap from '../../../../../public/images/home/swap.svg'

import GradientLogo from '../GradientLogoSvg'

const Crypto = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  const UsersCardData: IconCardData = {
    icon: <Image src={Farms} width="150" style={{ paddingLeft: '40px' }} />,
  }

  const TradesCardData: IconCardData = {
    icon: <Image src={Liqui} width="150" style={{ paddingLeft: '40px' }} />,
  }

  const StakedCardData: IconCardData = {
    icon: <Image src={Stake} width="150" style={{ paddingLeft: '40px' }} />,
  }
  const SwapCardData: IconCardData = {
    icon: <Image src={Swap} width="150" style={{ paddingLeft: '40px' }} />,
  }

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      {/* <GradientLogo height="48px" width="48px" mb="24px" /> */}
      <Heading textAlign="center" scale="xxl">
        {t('Earn passive income with ')}
      </Heading>
      <Heading textAlign="center" scale="xxl" mb="52px" color="secondary">
        {t('CRYPTO')}
      </Heading>
      {/* <Text textAlign="center" color="textSubtle">
        {t('PancakeSwap has the most users of any decentralized platform, ever.')}
      </Text> */}
      {/* <Flex flexWrap="wrap">
        <Text display="inline" textAlign="center" color="textSubtle" mb="20px">
          {entrusting}
          <>{tvl ? <>{tvlString}</> : <Skeleton display="inline-block" height={16} width={70} mt="2px" />}</>
          {inFunds}
        </Text>
      </Flex>

      <Text textAlign="center" color="textSubtle" bold mb="32px">
        {t('Will you join them?')}
      </Text> */}

      <Flex flexDirection={['column', 'column', 'column', 'column', 'row']}>
        <IconCard {...UsersCardData} mr={[null, null, null, '16px']} mb={['16px', '16px', '16px', '16px']}>
          <CryptoCardContent
            headingText={t('01', { users })}
            bodyText={t('Farms')}
            highlightColor={theme.colors.secondary}
          />
        </IconCard>
        <IconCard {...TradesCardData} mr={[null, null, null, '16px']} mb={['16px', '16px', '16px', '16px']}>
          <CryptoCardContent
            headingText={t('02', { trades })}
            bodyText={t('Liquidity Pools')}
            highlightColor={theme.colors.primary}
          />
        </IconCard>
        <IconCard {...StakedCardData}>
          <CryptoCardContent
            headingText={t('03', { tvl: tvlString })}
            bodyText={t('Staking')}
            highlightColor={theme.colors.failure}
          />
        </IconCard>
        <IconCard {...SwapCardData} mt={['16px', '16px', '16px', '16px', '0px']} ml={['16px', '16px', '16px', '16px']}>
          <CryptoCardContent
            headingText={t('02', { trades })}
            bodyText={t('Swap')}
            highlightColor={theme.colors.primary}
          />
        </IconCard>
      </Flex>
    </Flex>
  )
}

export default Crypto
