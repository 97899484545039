import { Heading, Flex, Text, Skeleton, ChartIcon, CommunityIcon, SwapIcon } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import useTheme from 'hooks/useTheme'
import { formatLocalisedCompactNumber } from 'utils/formatBalance'
import useSWRImmutable from 'swr/immutable'
import Image from 'next/image'
import PhoneIcon from '../../../../../public/images/phone.svg'

import GradientLogo from '../GradientLogoSvg'

const AllinOne = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { data: tvl } = useSWRImmutable('tvl')
  const { data: txCount } = useSWRImmutable('totalTx30Days')
  const { data: addressCount } = useSWRImmutable('addressCount30Days')
  const trades = formatLocalisedCompactNumber(txCount)
  const users = formatLocalisedCompactNumber(addressCount)
  const tvlString = tvl ? formatLocalisedCompactNumber(tvl) : '-'

  const tvlText = t('And those users are now entrusting the platform with over $%tvl% in funds.', { tvl: tvlString })
  const [entrusting, inFunds] = tvlText.split(tvlString)

  return (
    <>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        {/* <GradientLogo height="48px" width="48px" mb="24px" /> */}
        <Image src={PhoneIcon} width="500px" alt="phone" />
        <Heading textAlign="center" scale="xxl" mb="0px">
          {t('SWAP. STAKE. FARM')}
        </Heading>
        <Heading textAlign="center" scale="xxl" mb="32px" color="secondary">
          {t('ALL IN ONE PLACE')}
        </Heading>
        <Text display="inline" textAlign="center" color="textSubtle" mb="20px" fontSize="24" fontWeight="600">
          Synthetic assets provide exposure to an asset without holding the underlying resource. Synths offer many
          advantages, including reducing the friction when switching between trading assets and among different asset
          classes. For example, one can trade Apple shares to synthetic gold to Bitcoin. GEXPRESS is expanding the
          accessibility of certain assets and censorship resistance in one unified, easy-to-use, intuitive user
          interface.
        </Text>
      </Flex>
    </>
  )
}

export default AllinOne
