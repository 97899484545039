import { Heading, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'

const CryptoCardContent: React.FC<
  React.PropsWithChildren<{ headingText: string; bodyText: string; highlightColor: string }>
> = ({ headingText, bodyText, highlightColor }) => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const isSmallerScreen = isMobile || isTablet
  //   const split = headingText.split(' ')
  //   const lastWord = split.pop()
  //   const remainingWords = split.slice(0, split.length).join(' ')

  return (
    <Flex
      minHeight={[null, null, null, null]}
      height="250px"
      width="200px"
      flexDirection="column"
      // justifyContent="flex-end"
      mt={[null, null, null, null]}
    >
      {/* {isSmallerScreen && remainingWords.length > 13 ? (
        <Heading scale="lg">{remainingWords}</Heading>
      ) : (
        <Heading scale="xl">{remainingWords}</Heading>
      )} */}
      {/* <Heading ml={["76px", "76px", "76px", '76px']} color={highlightColor} scale="xl" mb="24px">
        {lastWord}
      </Heading> */}
      <Text color="secondary" mt={['196px', '196px', '196px', '196px']}>
        {bodyText}
      </Text>
    </Flex>
  )
}

export default CryptoCardContent
